<template>
    <div class="sweepstakes_wrap">
        <h1>扫码关注送礼品</h1>
        <div class="start_content">

            <div class="sweepstakes_title">
                <div class="sweepstakes_title_unpack">感谢您的关注，回答下方问题即可参与抽奖</div>
                <div class="sweepstakes_guan">
                    伟业计量是标准物质研究中心，它的官方网址是www.
                    <input
                        type="text"
                        name="name"
                        id="Q_answer"
                        class="sweepstakes_input"
                        maxlength="4"
                    />.com？（提示：网址是“标准物质”的拼音首字母）
                </div>
            </div>
            <div class="sweepstakes_content">
                <canvas class="Sweepstakes_dibu" id="myCanvas" width="700" height="550">
                    当前浏览器版本过低，请使用其他浏览器尝试
                </canvas>
                <img class="Sweepstakes_start" src="@/assets/img/lottery/start.png" id="start">
            </div>
        </div>

        <div class="sweepstakes_bottom">
            <div class="Sweepstakes_guize">
                <span class="Sweepstakes_guize_title" style="margin-top: 15px">活动规则</span>
                <div class="Sweepstakes_guize_content">
                    <p style="margin-top: 10px">1、活动对象：现场扫码关注伟业计量公众号的用户。</p>
                    <p>2、活动时间：2023年05月10日—2023年05月12日。</p>
                    <p>3、活动期间，每个用户仅限参与一次。</p>
                    <p>注：本活动最终解释权归伟业计量所有。</p>
                </div>
            </div>
        </div>

        <exhibition-reward-item
                v-if="showRedPrize"
                @close="onClosePrize"
                :src="awardImg"
                :awardName="awardName"
                hint="恭喜您获得"
        ></exhibition-reward-item>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import exhibitionRewardItem from "./exhibitionRewardItem.vue";
import {useRoute, useRouter} from "vue-router";
import qs from "qs";
export default defineComponent({
    name: "exhibitionLottery",
    components: {
        exhibitionRewardItem
    },
    setup(){
        const route = useRoute();
        let code = route.query.code as string;
        let state = route.query.state as string;
        const href = window.location.href;
        const codeReg = /code=(.*?)&/
        const stateReg = /state=(.*)/

        if (href.match(codeReg)) {
            code = RegExp.$1
        }
        if (href.match(stateReg)) {
            state = RegExp.$1
        }


      ($.fn as any).WheelSurf = function (options: any) {
          const _default = {
              outerCircle: {
                  color: '#df1e15'
              },
              innerCircle: {
                  color: '#f4ad26'
              },
              dots: ['#fbf0a9', '#fbb936'],
              disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
              title: {
                  color: '#5c1e08',
                  font: '19px Arial'
              }
          }

          $.extend(_default,options)
          // 画布中心移动到canvas中心

          /* eslint-disable */
          const _this = this[0],
              width = _this.width,
              height = _this.height,
              ctx = _this.getContext("2d"),
              imgs: any = [],
              awardTitle: any = [],
              awardPic: any = []
          for (const item in (_default as any).list) {
              awardTitle.push((_default as any).list[item].name)
              imgs.push((_default as any).list[item].image)
          }
          const num = imgs.length
          // 圆心
          const x = width / 2
          const y = height / 2
          ctx.translate(x, y)

          return {
              init: function (angelTo: any) {
                  angelTo = angelTo || 0;

                  ctx.clearRect(-this.width, -this.height, this.width, this.height);

                  // 平分角度
                  const angel = (2 * Math.PI / 360) * (360 / num);
                  let startAngel = 2 * Math.PI / 360 * (-90)
                  let endAngel = 2 * Math.PI / 360 * (-90) + angel

                  // 旋转画布
                  ctx.save()
                  ctx.rotate(angelTo * Math.PI / 180);
                  // 画外圆
                  ctx.beginPath();
                  ctx.lineWidth = 25;
                  ctx.strokeStyle = _default.outerCircle.color;
                  ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                  ctx.stroke();
                  // 画里圆
                  ctx.beginPath();
                  ctx.lineWidth = 23;
                  ctx.strokeStyle = _default.innerCircle.color;
                  ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                  ctx.stroke();

                  // 装饰点
                  const dotColor = _default.dots
                  for (let i = 0; i < 12; i++) {
                      // 装饰点 圆心 坐标计算
                      ctx.beginPath();
                      const radius = 230;
                      const xr = radius * Math.cos(startAngel)
                      const yr = radius * Math.sin(startAngel)

                      ctx.fillStyle = dotColor[i % dotColor.length]
                      ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                      ctx.fill()

                      startAngel += (2 * Math.PI / 360) * (360 / 12);

                  }
                  // 画里转盘
                  const colors = _default.disk
                  for (let i = 0; i < num; i++) {
                      ctx.beginPath();
                      ctx.lineWidth = 208;
                      ctx.strokeStyle = colors[i % colors.length]
                      ctx.arc(0, 0, 104, startAngel, endAngel)
                      ctx.stroke();
                      startAngel = endAngel
                      endAngel += angel
                  }
                  // 添加奖品
                  function loadImg() {

                      const dtd = $.Deferred()
                      let countImg = 0
                      if (awardPic.length) {
                          return dtd.resolve(awardPic);
                      }
                      for (let i = 0; i < num; i++) {
                          const img = new Image()
                          awardPic.push(img)

                          img.src = imgs[i]
                          img.onload = function () {
                              countImg++
                              if (countImg == num) {
                                  dtd.resolve(awardPic);
                              }
                          }
                      }
                      return dtd.promise()
                  }

                  $.when(loadImg()).done(function (awardPic) {

                      startAngel = angel / 2
                      for (let i = 0; i < num; i++) {
                          ctx.save();
                          ctx.rotate(startAngel)
                          ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                          ctx.font = _default.title.font;
                          ctx.fillStyle = _default.title.color
                          ctx.textAlign = "center";
                          ctx.fillText(awardTitle[i], 0, -170);
                          startAngel += angel
                          ctx.restore();
                      }
                  })
                  ctx.restore();
              },
              /**
               * @param angel 旋转角度
               * @param callback 转完后的回调函数
               */
              lottery: function (angel: any, callback: any) {
                  angel = angel || 0
                  angel = 360-angel
                  angel += 720
                  // 基值（减速）
                  const baseStep = 30
                  // 起始滚动速度
                  const baseSpeed = 0.3
                  // 步长
                  let count = 1;
                  const _this = this
                  const timer = setInterval(function () {

                      _this.init(count)
                      if (count == angel) {
                          clearInterval(timer)
                          if (typeof callback == "function") {
                              callback()
                          }
                      }

                      count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                      if (angel - count < 0.5) {
                          count = angel
                      }

                  }, 25)
              }
          }

      }
      let clickFlag = ref(false)
      const showRedPrize = ref(false)
      const awardName = ref()
      const awardImg = ref()
      const router = useRouter();
      //初始化查看奖品链接
      let rewardType=3;
      var wheelSurf: any
      // 初始化装盘数据 正常情况下应该由后台返回
      var initData = {
          "success": true,
          "list": [
              {
                  "id": 1,
                  "name": "办公小套装",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/c2492cf23e14b908cd590bef9d1f6e18.png",
                  "rank": 1,
                  "percent": 15
              },
              {
                  "id": 2,
                  "name": "精品玻璃杯",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/df432bb00ef50a88845ecd2db8987afc.png",
                  "rank": 2,
                  "percent": 10
              },
              {
                  "id": 3,
                  "name": "双肩包",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/c1b7321eae6801e13e42bcdb244ccc4a.png",
                  "rank": 3,
                  "percent": 10
              },
              {
                  "id": 4,
                  "name": "办公大套装",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/c370428e985814fb8ebf6f366417fca9.png",
                  "rank": 4,
                  "percent": 15
              },
              {
                  "id": 5,
                  "name": "伟业计量U盘",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/1099c0df6d029be84c04f7bd059864d8.png",
                  "rank": 5,
                  "percent": 10
              },
              {
                  "id": 6,
                  "name": "66.66-88.88随机红包",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
                  "rank": 6,
                  "percent": 10
              },
          ]
      }

      // 计算分配获奖概率(前提所有奖品概率相加100%)
      function getGift() {
          var percent = Math.random() * 100
          var totalPercent = 0
          for (var i = 0, l = initData.list.length; i < l; i++) {
              totalPercent += initData.list[i].percent
              if (percent <= totalPercent) {
                  console.log(initData.list[i]);
                  return initData.list[i];
              }
          }
      }

      var list = {}
      var angel = 360 / initData.list.length
      // 格式化成插件需要的奖品列表格式
      for (var i = 0, l = initData.list.length; i < l; i++) {
          list[initData.list[i].rank] = {
              id: initData.list[i].id,
              name: initData.list[i].name,
              image: initData.list[i].image
          }
      }
      var throttle = true;


      onMounted(() => {
        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()

        $("#Q_answer").blur(function () {
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              duration: 2000,
              type: "warning"
            });

            $(this).focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000
            });

            $(this).focus();
            return false;
          }
        });

        $("#start").on('click', function () {
            console.log("clickFlag",clickFlag.value)
            if(clickFlag.value){
                Toast({
                    title: "不可重复提交",
                    type: "warning",
                    duration: 2000,
                });
                return false;
            }
            const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
            if (answer == "") {
              Toast({
                title: "要先输入网址才能抽奖哦",
                type: "warning",
                duration: 2000,
              });

              $("#Q_answer").focus();
              return false;
            }
            if (answer != "bzwz") {
              Toast({
                title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
                type: "warning",
                duration: 2000,
              });
              $("#Q_answer").focus();
              return false;
            }
            var winData: any; // 正常情况下获奖信息应该由后台返回

            axios
                .post("/M/Event/doExhibitionLottery",
                    qs.stringify({
                        eventUniqueMark: 'Exhibition_Lottery_2023',
                        answer: answer,
                        code,
                        state,
                    })
              )
              .then((res: any) => {
                if (res.data.success) {
                    clickFlag.value =true;
                    if (res.data.msg == 'noFollow') {
                        Toast({
                            title: '您还未关注伟业计量微信公众号，请先关注再抽奖！',
                            type: "error",
                            duration: 2000
                        });
                        return false
                    }

                    winData = res.data.data
                    rewardType=res.data.data.rewardType
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Modal({
                        title: "提示",
                        content: res.data.msg,
                        optionControl:2,
                        confirmText: '确定',
                        onConfirm: () => {
                            window.location.href ="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8944b0df7341bd42" +
                                "&redirect_uri=http://m.bzwz.com/exhibitionLottery&response_type=code&scope=snsapi_userinfo&state=aoke#wechat_redirect"
                        },
                    });

                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });

      })


      function onClosePrize() {
        showRedPrize.value = false;

      }
        if(localStorage.getItem('WX')){
            setTimeout(function () {
                location.reload();
            },1000)
        }
        localStorage.removeItem('WX')
      return {

        showRedPrize,
        onClosePrize,
        awardImg,
        awardName,
      }
    }
});
</script>


<style lang="scss" scoped>
.sweepstakes_wrap {
    padding: 0.2rem;
   /* background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/15aae12becf76603fde501d871bab7bf.jpg');
    background-size: cover;*/
    padding-bottom: 50px;
}

.sweepstakes_wrap h1 {
    text-align: center;
    line-height: 40px;
    color: #000;
    font-size: 22px;
}

.start_content {
    /*background: url('../assets/img/startGift/gift3.png') no-repeat;*/
    /*background-size: 100%;*/
}

.start_desc_title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    img {
        width: 30%;
    }
}

.sweepstakes_title {
  margin: 0 auto;
  margin-top: 15px;
  width: 96%;
  color: #000000;
  padding-top: 15px;
  padding-bottom: 0;
}


.sweepstakes_title .sweepstakes_title_unpack,
.Sweepstakes_guize_title {
    font-size: 16.5px;
    font-weight: bold;
    position: relative;
    padding-left: 10px;
    margin-bottom: 0.2rem;
}

.sweepstakes_title .sweepstakes_title_unpack::after,
.Sweepstakes_guize_title::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    width: 3px;
    height: 17.5px;
    background: #df0024;
}


.sweepstakes_title p {
    font-size: 16px;
}

.sweepstakes_title p span {
    font-size: 12px;
    font-weight: 100;
}

.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_guan {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    line-height: 34px;
    font-weight: 500;
}

.sweepstakes_guan input {
  width: 65px;
  height: 23px;
  line-height: 23px;
  border: none;
  border-bottom: 0.5px solid;
  outline: none;
  text-align: center;
  font-size: 14px;
 /* background-color: #96090F;*/
  color: #000;
  overflow: hidden;
}
/*.sweepstakes_guan input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #96090F inset;
    -webkit-text-fill-color: #fff;
}*/

.sweepstakes_title span {
font-size: 0.25rem;
display: inline-block;
}

.sweepstakes_title span a {
    color: #0072bb;
}




.sweepstakes_content {
    text-align: center;
    position: relative;
    padding: 0.4rem 0;
    padding-top: 0;
    margin-top: 0.6rem;
}

.Sweepstakes_start {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.75rem;
    /*margin-top: -0.75rem;*/
    margin-top: -1.05rem;
    cursor: pointer;
    width: 1.5rem;
}

.Sweepstakes_dibu {
    width: 100%;
}

.challengeWhellSelect {
    width: 2.3rem;
    height: 25px;
    line-height: 25px;
    background: none;
    color: #fff;
    font-family: 'Microsoft YaHei';
    font-size: 14px;
}

.challengeWhellTitle {
    text-align: center;
    font-size: 0.28rem;
    line-height: 0.5rem;
}

.Sweepstakes_bottom_content {
    line-height: 0.6rem;
}

    .Sweepstakes_bottom_content p a {
        text-decoration: underline;
        color: #2a2aca;
    }

.Choice {
    width: 100%;
    height: auto;
    margin-top: 0.1rem;
}

label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.Choice input {
    vertical-align: middle;
    margin-right: 10px;
}

.lottery-text {
    position: absolute;
    left: 11%;
    top: 50%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.8rem;
    right: 1.25rem;
}
.sweepstakes_bottom{
    padding: 0 0.2rem;
}

.Sweepstakes_guize_content {
    color: #000;
    font-size: 14px;
}

.Sweepstakes_guize_content p {
    line-height: 30px;
}
</style>
